/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-lone-blocks */
import React from 'react'
import Parse from 'parse'
import { makeAutoObservable } from 'mobx'
import { UserRole } from '../Models/UserRole'
import { Kid } from '../Models/Kid'
import { School } from '../Models/School'
import { userStore } from './userStore'
import { USER_ROLE_STATUS, USER_ROLE_TYPE } from '../Enums/TyraEnums'
import { UserRoleConfiguration } from '../App/intefaces/configuration'
import { InviteNewGuardian } from '../views/admin/inviteHandleUsers/inviteParents/components/KidCardModal'
import { Department } from '../Models/Department'
import { InvitePersonalInterface } from '../views/admin/inviteHandleUsers/invitePersonal/invitePersonal'

class AdminStore {
  school?: School
  newUserRoles?: UserRole[]
  activeUserRoles?: UserRole[]
  pausedUserRoles?: UserRole[]
  pendingUserRoles?: UserRole[]
  constructor() {
    makeAutoObservable(this)
  }
  setSchool = (school: School) => {
    this.school = school
  }
  fetchSingleSchool = async (schoolId: string) => {
    const schoolQuery = new Parse.Query(School)
      .equalTo('objectId', schoolId)
      .include('school_settings')

    const school = await schoolQuery.first()
    if (school) {
      this.setSchool(school)
      return school
    }
  }

  fetchNewUserRoles = async (schoolId: string) => {
    const currentUserRole = userStore.currentUserRole
    const userRoleQuery = new Parse.Query(UserRole)
      .equalTo('status', USER_ROLE_STATUS.NEW)
      .equalTo('school', currentUserRole?.school)
      .include('user')
      .include('kid')
      .include('user.user_extra')
      .include('user.user_nin')
    const userRoles = await userRoleQuery.limit(1000).find()
    this.setNewUserRoles(userRoles)
  }
  fetchPendingUserRoles = async () => {
    const currentUserRole = userStore.currentUserRole
    const userRoleQuery = new Parse.Query(UserRole)
      .equalTo('status', USER_ROLE_STATUS.PENDING)
      .equalTo('school', currentUserRole?.school)
      .include('user')
      .include('kid')
      .include('user.user_extra')
      .include('user.user_nin')
    const userRoles = await userRoleQuery.limit(1000).find()
    this.setPendingUserRoles(userRoles)
  }
  fetchActiveUserRoles = async (schoolId: string) => {
    const currentUserRole = userStore.currentUserRole
    const userRoleQuery = new Parse.Query(UserRole)
      .equalTo('status', USER_ROLE_STATUS.ACTIVE)
      .equalTo('school', currentUserRole?.school)
      /*      .doesNotExist('archive_state')
      .doesNotExist('kid_is_deleted')
      .doesNotExist('user_is_deleted')*/
      .include('user')
      .include('kid')
      .include('user.user_extra')
      .include('user.user_nin')
    const userRoles = await userRoleQuery.limit(1000).find()
    this.setActiveUserRoles(userRoles)
  }
  fetchPausedUserRoles = async () => {
    const currentUserRole = userStore.currentUserRole
    const userRoleQuery = new Parse.Query(UserRole)
      .equalTo('status', USER_ROLE_STATUS.PAUSED)
      .equalTo('school', currentUserRole?.school)
      .include('user')
    const userRoles = await userRoleQuery.limit(1000).find()
    this.setPausedUserRoles(userRoles)
  }
  setNewUserRoles = (userRoles: UserRole[]) => {
    this.newUserRoles = userRoles
  }
  setActiveUserRoles = (userRoles: UserRole[]) => {
    this.activeUserRoles = userRoles
  }
  setPausedUserRoles = (userRoles: UserRole[]) => {
    this.pausedUserRoles = userRoles
  }
  setPendingUserRoles = (userRoles: UserRole[]) => {
    this.pendingUserRoles = userRoles
  }

  setUploadParent = async (value: boolean, userRoles: UserRole[]) => {
    for (const userRole of userRoles) {
      userRole.is_upload_document_parent = value
      await userRole.save()
    }
  }
  setUpdateStaffSchedule = async (value: boolean, userRoles: UserRole[]) => {
    for (const userRole of userRoles) {
      userRole.staff_schedule_responsable = value
      await userRole.save()
    }
  }
  setUpdateStaffSensetive = async (value: boolean, userRoles: UserRole[]) => {
    for (const userRole of userRoles) {
      userRole.staff_sensetive_info_bool = value
      await userRole.save()
    }
  }
  setUpdateStaffStatistics = async (value: boolean, userRoles: UserRole[]) => {
    for (const userRole of userRoles) {
      userRole.stat_access = value
      await userRole.save()
    }
  }
  setUpdateAdminPermission = async (value: number, userRoles: UserRole[]) => {
    for (const userRole of userRoles) {
      userRole.role_type = value
      await userRole.save()
    }
  }
  setUpdateSuperAdmin = async (value: number, userRoles: UserRole[]) => {
    for (const userRole of userRoles) {
      {
        userRole.role_type = value
        await userRole.save()
      }
    }
  }

  /*
  const userId = req.params.userId as string
  const userRoleId = req.params.userRoleId as string
  const admin = req.params.admin as boolean
  const scheduleResponsable = req.params.scheduleResponsable as boolean
  const sensetive = req.params.sensetive as boolean
  const statAccess = req.params.statAccess
  const invoice = req.params.invoice as boolean | undefined  // Deprecated, use superAdmin instead
  const superAdmin = invoice ?? req.params.superAdmin as boolean | undefined
  const schoolName = req.params.schoolName as string  // Deprecated
  const schoolId: ObjectId = req.params.schoolId
  const departmentId = req.params.departmentId as string | undefined
  const multiUserId = req.params.multi_user_id as string | undefined   // Deprecated
  const multiUserAccess = req.params.multiUserAccess as boolean | undefined
  const readOnly = req.params.readOnly  // If undefined, it doesnt touch anything.

  */
  setUserToSchoolAccount = async (value: boolean, userRoles: UserRole[]) => {
    for (const userRole of userRoles) {
      const updateUserRole = await Parse.Cloud.run('saveStaffChanges', {
        userId: userRole.user?.id,
        userRoleId: userRole.id,
        admin: userRole.role_type >= USER_ROLE_TYPE.ADMIN ? true : false,
        scheduleResponsable: userRole.staff_schedule_responsable,
        sensetive: userRole.staff_sensetive_info_bool,
        statAccess: userRole.stat_access,
        superAdmin:
          userRole.role_type === USER_ROLE_TYPE.SUPER_ADMIN ? true : false,
        schoolId: userRole.school_pointer.id,
        departmentId: userRole.staff_primary_dep
          ? userRole.staff_primary_dep.id
          : undefined,
        multiUserAccess: value,
        readOnly: undefined,
      })
        .then((response) => {
          /*          if (response) {
            userRole.multi_user = response.multi_user
          }*/
        })
        .finally(() => {})
      /*if (updateUserRole) {
        userRole.
      }*/
    }
  }

  setReadOnlyPermission = async (value: boolean, userRoles: UserRole[]) => {
    const configuration: UserRoleConfiguration = {
      checking: {
        readOnly: value,
      },
      upload: {
        readOnly: value,
      },
      absence: {
        readOnly: value,
      },
      activities: {
        readOnly: value,
      },
      ska: {
        readOnly: value,
      },
      lostAndFound: {
        readOnly: value,
      },
      events: {
        readOnly: value,
      },
      documents: {
        readOnly: value,
      },
      box: {
        readOnly: value,
      },
      notes: {
        readOnly: value,
      },
      childInfo: {
        readOnly: value,
      },
    }

    for (let userRole of userRoles) {
      userRole.configuration = configuration
      await userRole.save()
    }
  }
  updateSpecificReadOnlyPermission = async (
    configuration: any,
    type: string,
    value: boolean,
    userRoles: UserRole[],
  ) => {
    if (configuration) {
      for (let key in configuration) {
        if (configuration.hasOwnProperty(key) && key === type) {
          configuration[key].readOnly = value
        }
      }
      for (let userRole of userRoles) {
        userRole.configuration = configuration
        await userRole.save()
      }
    } else {
      const initialConfiguration: any = {
        checking: {
          readOnly: false,
        },
        upload: {
          readOnly: false,
        },
        absence: {
          readOnly: false,
        },
        activities: {
          readOnly: false,
        },
        ska: {
          readOnly: false,
        },
        lostAndFound: {
          readOnly: false,
        },
        events: {
          readOnly: false,
        },
        documents: {
          readOnly: false,
        },
        box: {
          readOnly: false,
        },
        notes: {
          readOnly: false,
        },
        childInfo: {
          readOnly: false,
        },
      }
      for (let key in initialConfiguration) {
        if (initialConfiguration.hasOwnProperty(key) && key === type) {
          initialConfiguration[key].readOnly = value
        }
      }
      for (let userRole of userRoles) {
        userRole.configuration = initialConfiguration
        await userRole.save()
      }
    }
  }

  cancelUserRole = async (userRoles: UserRole[]) => {
    for (const userRole of userRoles) {
      const cancelUserRole = await Parse.Cloud.run('deleteUserRole', {
        userRoleId: userRole.id,
      })
    }
  }

  removeUserRole = async (userRole: UserRole) => {
    const cancelUserRole = await Parse.Cloud.run('deleteOwnUserRole', {
      userRoleId: userRole.id,
      sendPush: true,
    })
  }

  deleteUserAccount = async (userRoles: UserRole[], value: Number) => {
    for (const userRole of userRoles) {
      if (userRole.user) {
        const cancelUserRole = await Parse.Cloud.run('deleteUserAcc', {
          userId: userRole.user.id,
          value: value,
        })
      }
    }
  }

  pauseUserRole = async (userRoles: UserRole[]) => {
    for (const userRole of userRoles) {
      userRole.status = USER_ROLE_STATUS.PAUSED
      userRole.user_role_setting.status = USER_ROLE_STATUS.PAUSED
      userRole.last_modified = new Date()
      userRole.set('sendPush', true)
      await userRole.save()
      await Parse.Cloud.run('createInviteNotification', {
        schoolName: userRole.school,
        schoolId: userRole.school_pointer.id,
        creatorId: userStore.currentUser?.id,
        sendPush: true,
        originId: userRole.id,
        dest_type: 2,
        userRoleStatus: userRole.status,
        extraInfo: [userRole.status],
        userId: userRole.user?.id,
        reactivated: false,
      })
    }
  }

  activateUserRole = async (userRoles: UserRole[]) => {
    for (const userRole of userRoles) {
      const reactivate =
        userRole.status === USER_ROLE_STATUS.PAUSED ? true : false
      userRole.status = USER_ROLE_STATUS.ACTIVE
      userRole.user_role_setting.status = USER_ROLE_STATUS.ACTIVE
      userRole.last_modified = new Date()
      userRole.set('sendPush', true)
      await userRole.save()
      await Parse.Cloud.run('createInviteNotification', {
        schoolName: userRole.school,
        schoolId: userRole.school_pointer.id,
        creatorId: userStore.currentUser?.id,
        sendPush: true,
        originId: userRole.id,
        dest_type: 2,
        userRoleStatus: userRole.status,
        extraInfo: [userRole.status],
        userId: userRole.user?.id,
        reactivated: reactivate,
      })
    }
  }
  setWaitingUserRole = async (userRole: UserRole) => {
    userRole.status = USER_ROLE_STATUS.PENDING
    userRole.user_role_setting.status = USER_ROLE_STATUS.PENDING
    userRole.last_modified = new Date()
    userRole.set('sendPush', true)
    await userRole.save()
    await Parse.Cloud.run('createInviteNotification', {
      schoolName: userRole.school,
      schoolId: userRole.school_pointer.id,
      creatorId: userStore.currentUser?.id,
      sendPush: true,
      originId: userRole.id,
      dest_type: 2,
      userRoleStatus: userRole.status,
      extraInfo: [userRole.status],
      userId: userRole.user?.id,
      reactivated: false,
    })
  }
  approveUserRole = async (userRoles: UserRole[]) => {
    for (const userRole of userRoles) {
      userRole.status = USER_ROLE_STATUS.ACTIVE
      userRole.user_role_setting.status = USER_ROLE_STATUS.ACTIVE
      userRole.last_modified = new Date()
      userRole.set('sendPush', true)
      await userRole.save()
      await Parse.Cloud.run('createInviteNotification', {
        schoolName: userRole.school,
        schoolId: userRole.school_pointer.id,
        creatorId: userStore.currentUser?.id,
        sendPush: true,
        originId: userRole.id,
        dest_type: 2,
        userRoleStatus: userRole.status,
        extraInfo: [userRole.status],
        userId: userRole.user?.id,
        reactivated: false,
      })
    }
  }
  inviteTeacher = async (values: InvitePersonalInterface, schoolId: string) => {
    /*
    UNKNOWN = 0,
  EMAIL = 20,
  PHONE = 30,
  NIN = 40
  */

    const inviteTeacher = await Parse.Cloud.run('inviteTeacher', {
      schoolId: schoolId,
      loginMethod:
        values.type === 'mobile'
          ? 30
          : values.type === 'ssn'
          ? 40
          : values.type === 'email'
          ? 20
          : 0,
      phoneNr: values.type === 'mobile' ? values.username : undefined,
      email: values.type === 'email' ? values.username : undefined,
      nin: values.type === 'ssn' ? values.username.replace('-', '') : undefined,
      inviteNote: values.name,
      admin: values.admin,
      sensetive: values.staff_sensitive_information,
      scheduleResponsable: values.staff_scheduele,
      statAccess: values.staff_statistics,
      superAdmin: values.super_admin,
      directActivation: undefined,
      primaryDepId: values.primary_department?.id,
      depIds: values.primary_department ? [values.primary_department.id] : [],
      multi_user_id: undefined, // values.school_account
      multiUserAccess: values.school_account,
      readOnly: values.configuration ? false : true, // VAD HÄR?
      config: JSON.stringify(values.configuration),
    })
      .then((response) => {
        return response
      })
      .catch((error) => {
        // HANTERA FELMEDDELANDE
        const errorMessage = JSON.parse(JSON.stringify(error))
        if (errorMessage.message.message === 'ALREADY_INVITED') {
          return 'ALREADY_INVITED'
        } else {
          return 'ERROR'
        }
      })
    return inviteTeacher
  }

  inviteExistingGuardian = async (
    schoolId: string,
    kidId: string,
    userId: string,
    isUploadDocumentParent: boolean,
    inviteNote?: string,
  ) => {
    const inviteExistingGuardian = await Parse.Cloud.run(
      'inviteExistingGuardian',
      {
        schoolId,
        kidId,
        userId,
        isUploadDocumentParent,
        inviteNote: inviteNote ?? undefined,
      },
    )
      .then((response) => {
        return 'OK'
      })
      .catch((error) => {
        // HANTERA FELMEDDELANDE
        const errorMessage = JSON.parse(JSON.stringify(error))
        if (errorMessage.message.message === 'ALREADY_INVITED') {
          return 'ALREADY_INVITED'
        } else {
          return 'ERROR'
        }
      })
    return 'OK'
  }

  inviteGuardian = async (
    schoolId: string,
    kid: Kid,
    newGuardian: InviteNewGuardian,
  ) => {
    const inviteGuardian = await Parse.Cloud.run('inviteGuardian', {
      schoolId: schoolId,
      kidId: kid.id,
      loginMethod:
        newGuardian.type === 'mobil'
          ? 30
          : newGuardian.type === 'bankid'
          ? 40
          : newGuardian.type === 'email'
          ? 20
          : 0,
      isUploadDocumentParent: newGuardian.uploadParent,
      inviteNote: newGuardian.name,
      depIds: [kid.departmentPointer.id],
      //personId: newGuardian.ssn,
      phoneNr: newGuardian.phone,
      email: newGuardian.email,
      nin: newGuardian.ssn.replace('-', ''),
    })
      .then((response) => {
        return 'OK'
      })
      .catch((error) => {
        // HANTERA FELMEDDELANDE
        const errorMessage = JSON.parse(JSON.stringify(error))
        if (errorMessage.message.message === 'ALREADY_INVITED') {
          return 'ALREADY_INVITED'
        } else {
          return 'ERROR'
        }
      })
    return 'OK'
  }

  setStaffPrimaryDepartment = async (
    depId: string | null,
    userRole: UserRole,
  ) => {
    userRole.set(
      'staff_primary_dep',
      depId ? Department.createWithoutData(depId) : undefined,
    )
    await userRole.save()
  }
}

export const adminStore = (() => {
  return new AdminStore()
})()
export const AdminStoreContext: React.Context<AdminStore> =
  React.createContext(adminStore)
