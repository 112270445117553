import React, { useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import Page from '../../components/Page'
import { DepartmentChooser } from '../documents/components/DepartmentChooser'
import { useTranslation } from 'react-i18next'
import { NavigationStoreContext } from '../../store/navigationStore'
import { UserStoreContext } from '../../store/userStore'
import { KidsStoreContext } from '../../store/kidsStore'
import {
  faEyeSlash,
  faMagnifyingGlass,
  faPlus,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { KidModal } from './KidModal'
import { Kid } from '../../Models/Kid'
import { DepartmentStoreContext } from '../../store/departmentStore'
import { getImageOrPlaceHolder } from '../../utils/parseUtils'
import classNames from 'classnames'
import { USER_ROLE_TYPE } from '../../Enums/TyraEnums'
import { boolean, string } from 'yup'
import ColorCard from '../../components/ColorCard'
import { Button } from '../../components/Button'
import { faBirthdayCake, faPenSwirl } from '@fortawesome/pro-solid-svg-icons'
import { RandomColors } from '../../components/RandomColors'
import Multiselect from '../../components/Multiselect'
import { Department } from '../../Models/Department'
import ModalRegular from '../../components/ModalRegular'
import MultiSelectOpen from '../../components/MultiSelectOpen'
import { Loading } from '../../components/Loading'
import moment from 'moment'

export const OurKids: React.FC = observer(() => {
  const [showFilter, setShowFilter] = useState(false)
  const { t } = useTranslation()
  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(false)
  /*const [search, setSearch] = useState('')*/
  const [selectedKid, setSelectedKid] = useState<Kid | undefined>()
  const { savedUserRoleId, currentUserRole, fetchUserRole, readOnly } =
    useContext(UserStoreContext)
  const { fetchKids, fetchUserRoles, kids, setSearch, search } =
    useContext(KidsStoreContext)
  const {
    departments,
    selectedDepartment,
    fetchDepartments,
    setSelectedDepartments,
    selectedDepartments,
  } = useContext(DepartmentStoreContext)
  const { setTitle } = useContext(NavigationStoreContext)
  useEffect(() => {
    if (currentUserRole) {
      setTitle(
        currentUserRole?.role_type > USER_ROLE_TYPE.PARENT
          ? t('menu.ourKids')
          : t('menu.classList'),
      )
    }
  }, [t, currentUserRole?.id])

  useEffect(() => {
    if (savedUserRoleId) {
      fetchUserRole(savedUserRoleId).finally(() => {})
    }
  }, [savedUserRoleId])

  useEffect(() => {
    if (currentUserRole && currentUserRole?.school_pointer) {
      setLoading(true)
      fetchKids(currentUserRole)
      fetchDepartments(currentUserRole?.attributes?.school_pointer?.id, true)
      fetchUserRoles(currentUserRole).finally(() => {
        setLoading(false)
      })
    }
  }, [currentUserRole?.id])
  {
    /*const {randomColor, setRandomColor} = */
  }

  console.log('currentUserRole', currentUserRole)
  return (
    <Page
      naked
      clearFilter={() => {
        setSelectedDepartments([])
        if (currentUserRole && currentUserRole?.school_pointer) {
          fetchKids(currentUserRole)
        }
      }}
      filterCount={
        (selectedDepartments?.length ? selectedDepartments.length : 0) +
        (search ? 1 : 0)
      }
      filter={
        <div className="space-y-2">
          <div className="w-full flex justify-center space-x-2  ">
            <MultiSelectOpen
              onChange={(e) => {
                setSelectedDepartments(e)
                if (currentUserRole && currentUserRole?.school_pointer) {
                  fetchKids(currentUserRole)
                }
              }}
              items={departments as Department[]}
              initialSelectedItems={selectedDepartments}
              itemVariableKey={'klassName'}
              placeholder={t('general.department')}
            />

            {/*<Button size="md" label={t('general.save')} onClick={() => {}} />*/}
          </div>
        </div>
      }
      searchFilter={
        <SearchKids
          onSearch={(s: string) => {
            setSearch(s)
          }}
          initialValue={search}
        />
      }
      topBar={<>{/*<DepartmentChooser />*/}</>}
    >
      {loading && <Loading />}

      {/*loading && <Loading />*/}
      <div className={'flex justify-center w-full flex-col md:space-y-10 '}>
        {departments
          ?.filter((dep) => {
            if (!selectedDepartments?.length) {
              return true
            }

            return selectedDepartments?.find((selDep) => selDep.id === dep.id)
          })
          .map((department, index) => {
            return (
              <>
                <ColorCard
                  title={department?.attributes?.klassName + ''}
                  bgColor={
                    department.get('departmentColor')
                      ? 'bg-[' + department.get('departmentColor') + ']'
                      : RandomColors[index]
                  }
                >
                  {department.get('departmentColor') ? (
                    <div className="flex flex-wrap justify-center py-44 text-lg sm:text-2xl text-gray-300 w-full ">
                      {t('ourKids.noContactInfo')}
                    </div>
                  ) : (
                    <div
                      className={
                        'flex flex-wrap max-w-7xl py-3 font-medium justify-center'
                      }
                    >
                      {kids
                        ?.filter((k) => {
                          if (search?.length) {
                            return (
                              k?.attributes?.departmentPointer?.id ===
                                department?.id &&
                              k?.attributes?.kidName
                                ?.toLowerCase()
                                .includes(search.toLowerCase())
                            )
                          }
                          return (
                            k?.attributes?.departmentPointer?.id ===
                            department?.id
                          )
                        })
                        .sort((a, b) => {
                          return a?.attributes?.kidName.localeCompare(
                            b?.attributes?.kidName,
                          )
                        })
                        .map((kid) => {
                          const isBirthdayToday =
                            kid?.attributes?.birth_date &&
                            moment().format('MM-DD') ===
                              moment(kid?.attributes?.birth_date).format(
                                'MM-DD',
                              )
                          return (
                            <div
                              key={kid?.id}
                              onClick={() => {
                                setSelectedKid(kid)
                                setShowModal(true)
                              }}
                              className={
                                'flex flex-col space-y-2 items-center justify-center p-3 cursor-pointer hover:shadow-md hover:bg-gray-50 rounded-2xl '
                              }
                            >
                              <div className="relative">
                                {kid?.attributes?.hidden_in_dep_list && (
                                  <FontAwesomeIcon
                                    className="absolute -top-1 -right-1 text-eventsMain"
                                    icon={faEyeSlash}
                                    size={'1x'}
                                  />
                                )}

                                <img
                                  className={
                                    'w-[80px] h-[80px] rounded-full object-cover shadow'
                                  }
                                  src={getImageOrPlaceHolder(
                                    kid?.attributes?.kidImage?._url,
                                  )}
                                  alt=""
                                />
                                {isBirthdayToday && (
                                  <FontAwesomeIcon
                                    icon={faBirthdayCake}
                                    className="absolute -top-1 -left-1 text-purple-400"
                                  />
                                )}

                                {currentUserRole?.attributes?.role_type >
                                  USER_ROLE_TYPE.PARENT &&
                                  !currentUserRole?.attributes?.school_pointer
                                    ?.attributes?.school_setting?.attributes
                                    ?.hidden_other &&
                                  kid?.attributes?.kid_extra?.attributes
                                    ?.other_info && (
                                    <div
                                      className={
                                        'h-[16px] w-[16px] absolute bg-contactsMain right-2 -bottom-[1px] border-2 border-white  rounded-full'
                                      }
                                    />
                                  )}
                                {currentUserRole?.attributes?.role_type >
                                  USER_ROLE_TYPE.PARENT &&
                                  !currentUserRole?.attributes?.school_pointer
                                    ?.attributes?.school_setting?.attributes
                                    ?.hidden_allergies &&
                                  kid?.attributes?.kid_extra?.attributes
                                    ?.allergies && (
                                    <div
                                      className={
                                        'h-[16px] w-[16px] absolute bg-absenceMain left-2 -bottom-[1px] border-2 border-white rounded-full'
                                      }
                                    />
                                  )}
                              </div>
                              <p>{kid?.attributes.kidName}</p>
                            </div>
                          )
                        })}
                    </div>
                  )}
                </ColorCard>
              </>
            )
          })}
      </div>
      <KidModal
        showModal={showModal}
        kid={selectedKid}
        onClose={() => {
          setShowModal(false)
        }}
      />
    </Page>
  )
})

export const SearchKids = ({
  onSearch,
  initialValue,
}: {
  onSearch: any
  initialValue?: string
}) => {
  const { t } = useTranslation()

  const [text, setText] = useState('')
  return (
    <input
      className={classNames(
        'w-full px-4 py-3 placeholder-gray-400 border-gray-300 rounded-full shadow appearance-none focus:outline-none focus:ring-eventsMain focus:border-eventsMain sm:text-sm',
      )}
      type={'text'}
      defaultValue={initialValue}
      value={text ? text : initialValue}
      placeholder={t('ourKids.search')}
      onChange={(e) => {
        setText(e.target.value)
        onSearch(e.target.value)
      }}
    />
  )
}
